/*
MAKITA STORE
URL: service.makita.co.id
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Aug 7, 2023
UPDATE DATE: Aug 7, 2023
REVISION: 1
NAME: global.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: IndoMak | ...
PALETTE:  1. #FE0000 / rgb(254, 0, 0) (Pure Red / Red)
          2. #0C8190 / rgb(12, 129, 144) (Dark Cyan / Blue Chill)
          3. #0C5160 / rgb(12, 113, 128) (Very Dark Cyan / Deep Sea Green)
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@import './variable.scss';

@font-face{
  font-family: 'Aspekta';
  src: url('./assets/font/Aspekta-400.woff2') format('woff2'),
       url('./assets/font/Aspekta-400.woff') format('woff'),
       url('./assets/font/Aspekta-400.ttf') format('ttf'),
       url('./assets/font/Aspekta-400.eot') format('eot'),
       url('./assets/font/Aspekta-400.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Aspekta';
  src: url('./assets/font/Aspekta-700.woff2') format('woff2'),
       url('./assets/font/Aspekta-700.woff') format('woff'),
       url('./assets/font/Aspekta-700.ttf') format('ttf'),
       url('./assets/font/Aspekta-700.eot') format('eot'),
       url('./assets/font/Aspekta-700.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Aspekta';
  src: url('./assets/font/Aspekta-200.woff2') format('woff2'),
       url('./assets/font/Aspekta-200.woff') format('woff'),
       url('./assets/font/Aspekta-200.ttf') format('ttf'),
       url('./assets/font/Aspekta-200.eot') format('eot'),
       url('./assets/font/Aspekta-200.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: $colorText;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Aspekta', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

svg{
  display: inline-block;
  vertical-align: middle;
}

a{
  text-decoration: none;
  color: $color1;
  @extend %transition;

  &:hover{
    color: $color2;
  }
  
  &[disabled]{
    pointer-events: none;
    opacity: .5;
  }
}

input[type="text"],
input[type="password"],
input[type="file"],
textarea,
button{
  display: block;
  color: $colorText;
  font-size: 16px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: 'Aspekta', Helvetica, Arial, sans-serif;
  @extend %transition;

  &:focus{
    outline: none;
  }

  &.on-right{
    text-align: right;
  }

  &:disabled,
  &[disabled]{
    pointer-events: none;
    opacity: .5;
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button{
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

ul.floating{
  overflow: auto;
  margin: -$spacing2;

  &.none{
    margin: 0;
    
    > li{
      padding: 0;
    }
  }

  &.sub{
    margin: -$spacing3;

    > li{
      padding: $spacing3;
    }
  }
  
  &.lite{
    margin: -$spacing4;

    > li{
      padding: $spacing4;
    }
  }

  > li{
    float: left;
    width: 100%;
    padding: $spacing2;
    box-sizing: border-box;

    @for $i from 1 through 99{
      &.lay-#{$i} { 
        width: $i * 1%;
      }
    }

    &.clear{
      clear: both;
    }

    &.lay-333{
      width: 33.33%;
    }
  }
}

.title{
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  font-weight: bold;

  > span{
    display: block;
    font-weight: normal;
    color: $colorLabel;
    font-size: 16px;
    line-height: 22px;
    margin: $spacing4 auto 0 auto;
    width: 75%;
  }
}

.forming{
  > li{
    position: relative;

    &.req{
      color: $colorError;
      font-size: 11px;
      line-height: 11px;
      font-weight: bold;
      padding-top: $spacing4 !important;
      padding-bottom: $spacing4 !important;
    }
    
    &.fieldset{
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 2px;
      color: $colorLabel;
      font-size: 12px;
      line-height: 16px;
      padding: $spacing4 $spacing3 !important;
    }
  }

  &.lite{
    label{
      &.focus{
        top: 0;
        left: $spacing;
      }
    }
    
    .input-notif{
      right: $spacing;
    }
  }

  &.phone{
    margin: -$spacing3 !important;

    > li{
      &:first-child{
        padding-right: 0;

        > input{
          border-radius: $radius2 0 0 $radius2;
          border-right: none;
          text-align: center;
          padding: 0;
        }
      }

      &:last-child{
        padding-left: 0;

        > input{
          border-radius: 0 $radius2 $radius2 0;
        }

        .labeling{
          left: $spacing2;
        }
      }
    }
  }

  > li{
    position: relative;

    &.req{
      color: $colorError;
      font-size: 11px;
      line-height: 11px;
      font-weight: bold;
      padding-top: $spacing4 !important;
      padding-bottom: $spacing4 !important;
    }
  }

  &.none{
    label{
      left: 25px;

      &.focus{
        top: -5px;
        transform: translate(0, 0);
      }
    }
  }

  .labeling{
    position: absolute;
    pointer-events: none;
    left: 25px;
    top: 50%;
    transform: translate(0, -50%);
    background: #FFF;
    width: auto;
    color: $color2;
    padding: 3px 5px;
    font-size: 16px;
    line-height: 16px;
    opacity: .5;
    border-radius: $radius4;
    z-index: 1;
    @extend %transition;

    &.focus{
      top: $spacing4 - 3;
      transform: translate(0, 0);
      font-size: 11px;
      line-height: 11px;
      font-weight: bold;
      color: #FFF;
      background: $color2;
      opacity: 1;

      &.lite{
        top: 0;
      }

      // > span{
      //   color: #FFF;
      // }
    }

    > span{
      color: $colorError;
    }
  }

  input,
  textarea{
    width: 100%;
    border: 1px solid $color2;
    height: 45px;
    border-radius: $radius2;
    padding: 0 $spacing;

    &.error{
      border: 1px solid $colorError;
      padding: 0 41px 0 $spacing;
    }

    &.error{
      border: 1px solid $colorError;
      padding: 0 41px 0 $spacing;
    }

    &.lite{
      height: 35px;
      font-size: 14px;
      border-radius: $radius3;
    }

    &:disabled,
    &[disabled]{
      color: #000;
    }
  }

  textarea{
    resize: none;
    height: 100px;
    padding: $spacing3 $spacing;
  }

  // .input-decor{
  //   pointer-events: none;
  //   position: absolute;
  //   left: $spacing3;
  //   top: 50%;
  //   transform: translate(0, -50%);
  //   width: 0;
  //   height: 13px;
  //   background: $color1;
  //   opacity: .25;
  //   @extend %transition;

  //   &.focus{
  //     width: $spacing3;
  //   }
  // }

  .input-notif{
    pointer-events: none;
    position: absolute;
    right: $spacing + 5;
    top: 50%;
    transform: translate(0, -50%);
    color: #FFF;
    width: 0;
    height: 0;
    border-radius: 20px;
    opacity: 0;
    @extend %transition;

    &.show{
      opacity: 1;
      width: 20px;
      height: 20px;
    }

    &.error{
      background: $colorError;
    }

    &.success{
      background: $colorSuccess;
    }

    &.loading{
      background: $color1;

      > svg{
        padding: 0;
        animation: loadingInput 1.5s infinite linear;

        > path,
        > polygon{
          opacity: 1;
          animation: none;

          &:nth-child(1){
            opacity: .2;
          }
        }
      }
    }

    > svg{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      color: #FFF !important;
      
    }

    @keyframes loadingInput{
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  ul.forming-check{
    width: 100%;
    clear: both;
    padding: 0 $spacing3;
    border: 1px solid $color1;
    border-radius: $radius2;
    box-sizing: border-box;

    li{
      float: none;
      display: inline-block;
      padding: 11px 10px 12px 10px;

      label{
        position: relative;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        left: auto;
        pointer-events: all;
        opacity: 1;
        margin: 0;
        padding: 0 0 0 30px;
        color: $colorText;
        background: transparent;
        text-transform: none;
        cursor: pointer;
        @extend %transition;

        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          z-index: 1;
          background: #FFF;
          border: 1px solid $color1;
          border-radius: 5px;
          box-sizing: border-box;
          @extend %transition;
        }

        &:after{
          content: '';
          position: absolute;
          opacity: 0;
          left: 9px;
          top: 1px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          z-index: 2;
          @extend %transition;
        }

        input{
          position: absolute;
          height: auto;
          width: auto;
          top: 0;
          left: 0;
          z-index: 0;
        }

        &:hover{
          // color: $colorText;
          
          &:before{
            border: 1px solid $color1;
          }
        }

        &.checked{
          color: $color1;

          &:before{
            background: $color1;
            border: 1px solid $color1;
          } 
  
          &:after{
            opacity: 1;
          }
        }
      }
    }

    &.as-radio{
      li{
        label{
          &:before{
            border-radius: 26px;
          }

          &:after{
            top: 12px;
            left: 12px;
            height: 0;
            width: 0;
            border-radius: 15px;
            border: none;
            background: #FFF;
            transform: rotate(0deg);
            // opacity: 0;
            box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
          }

          &.checked{
            &:after{
              top: 6px;
              left: 6px;
              height: 12px;
              width: 12px;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .form-desc{
    color: $colorLabel;
    line-height: 16px;

    > a{
      font-weight: bold;
    }
  }
}

.btn{
  position: relative;
  width: 100%;
  background: $color1;
  color: #FFF;
  text-transform: uppercase;
  border-radius: $radius2;
  font-weight: bold;
  margin: 0 auto;
  height: 50px;

  &:hover{
    background: $color2;

    > span{
      &:before{
        background: $color1;
      }
    }
  }

  &:disabled,
  &[disabled]{
    background: $colorLabel;

    > span{
      &:before{
        height: 0;
      }
    }
  }

  &.btn-lite{
    height: 35px;
    font-size: 12px;
    padding: 0 $spacing3;
    border-radius: $radius3;

    svg{
      width: 14px;
      height: 14px;
    }

    > span{
      line-height: 35px;
      height: 35px;

      &:before{
        height: 5px;
      }
    }
  }

  &.btn-stats{
    border-radius: 100px;
  }

  &.btn-invert{
    background: $color2;

    > span{
      &:before{
        background: $color1;
      }
    }

    &:hover{
      background: $color1;
  
      > span{
        &:before{
          background: $color2;
        }
      }
    }
  }

  &.btn-red{
    background: $colorError;

    &:hover{
      background: $colorError;
    }
  }
  
  &.inactive{
    background: $colorLabel;

    &:hover{
      background: $colorText;
    }
  }
  
  &.icon-center{
    svg{
      margin: -3px 0 0 0;
    }
  }

  svg{
    width: 20px;
    height: 20px;
    margin: -3px 0 0 $spacing4;
  }

  > span{
    position: relative;
    display: inline-block;
    line-height: 50px;
    height: 50px;

    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 10px;
      background: $color2;
      @extend %transition;
    }
  }

  > ul{
    > li{
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $color1;
      margin: 0 ($spacing4 / 2);
      animation: btn .75s infinite ease-in-out;

      &:nth-child(2){
        animation-delay: 0.1s;
      }

      &:nth-child(3){
        animation-delay: 0.2s;
      }
    }
  }
}

a.btn{
  display: block;
  width: auto;
  line-height: 50px;
  text-align: center;

  &.btn-lite{
    line-height: 35px;
  }

  &:hover{
    color: #FFF;
  }
}

@keyframes btn{
  0% { opacity: .5; transform: scale(1, 1); }
  50% { opacity: 1; transform: scale(1, 2); }
  100% { opacity: .5; transform: scale(1, 1); }
}

.as-text{
  background: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: $color1;

  &:hover{
    color: $color2;
  }

  > svg{
    margin: -3px $spacing4 0 0;
  }
}

.loading{
  text-align: center;

  &.page{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
  }

  > svg{
    display: block;
    margin: 0 auto;
    padding: $spacing2 $spacing3;
    width: 125px;
    color: #FFF;
    background: $color1;
    border-radius: $radius3;
    // animation: loading 1.5s infinite linear;

    > path,
    > polygon{
      opacity: 0;
      animation: blink 1s infinite ease-in-out;

      &:nth-child(1){
        animation-delay: 0;
      }

      &:nth-child(2){
        animation-delay: 0.1s;
      }

      &:nth-child(3){
        animation-delay: 0.2s;
      }

      &:nth-child(4){
        animation-delay: 0.3s;
      }

      &:nth-child(5){
        animation-delay: 0.3s;
      }
      
      &:nth-child(6){
        animation-delay: 0.4s;
      }
      
      &:nth-child(7){
        animation-delay: 0.5s;
      }
    }
  }

  > div{
    margin: $spacing2 0 0 0;
    color: $colorLabel;
  }
}

@keyframes blink{
  0% { opacity: 0; transform: translate(-15px, 0); }
  25% { opacity: 1; transform: translate(0, 0);}
  75% { opacity: 1; transform: translate(0, 0);}
  100% { opacity: 0; transform: translate(15px, 0);}
}

// @keyframes loading{
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }

.react-select{
  clear: both;
  // margin: 20px 0 0 0;

  .react-select__control{
    border-radius: 5px;
    min-height: 45px;
    border: 1px solid $color2;
    border-radius: $radius2;
    cursor: pointer;
    box-shadow: none !important;

    .react-select__value-container{
      padding: 0 15px;

      .react-select__placeholder{
        font-size: 16px;
        color: $color2;
      }

      .react-select__single-value{
        margin: 0;
        color: $colorText;
        font-size: 16px;
        line-height: 20px;
      }

      input{
        height: 26px;
        font-size: 16px !important;
      }
    }

    .react-select__indicators{
      .react-select__indicator-separator{
        margin: 0;
        background-color: $color2;
        @extend %transition;
      }

      .react-select__indicator{
        padding: 0 5px;
        width: 37px;
        box-sizing: border-box;
        color: $color1;

        svg{
          margin: 0 auto;
          @extend %transition;
        }
      }
    }

    &.react-select__control--menu-is-open{
      border-radius: $radius2 $radius2 0 0;

      .react-select__indicators{
        .react-select__indicator{
          svg{
            transform: rotate(180deg);
          }
        }
      }
    }
    
    &:hover{
      border: 1px solid $color1 !important;
      border-color: $color1 !important;

      .react-select__indicators{
        .react-select__indicator-separator{
          background-color: $color1;
        }
      }

      .react-select__indicator{
        color: $color1;
      }
    }

    &:focus,
    &.react-select__control--is-focused{
      border: 1px solid $color1 !important;
      border-color: $color1 !important;

      .react-select__indicators{
        .react-select__indicator-separator{
          background-color: $color1;
        }
      }

      .react-select__indicator{
        color: $color1;
      }
    }
  }

  &.lite{
    .react-select__control{
      min-height: 35px;
      border-radius: $radius3;

      &.react-select__control--menu-is-open{
        border-radius: $radius3 $radius3 0 0;
      }

      .react-select__value-container{
        .react-select__placeholder{
          font-size: 14px;
        }

        .react-select__single-value{
          font-size: 14px;
          line-height: 14px;
        }

        input{
          height: 26px;
          font-size: 14px !important;
        }
      }
    }
  }

  &.react-select--is-disabled{
    .react-select__control{
      background: transparent;
      pointer-events: none;
      opacity: .5;
    }
  }
}

.react-select__menu-portal{
  .react-select__menu{
    border-radius: 0 0 $radius2 $radius2;
    margin: 0;
    overflow: hidden;
    box-shadow: 0 15px 10px -5px rgba(0, 0, 0, .05);
    border: 1px solid $color1;
    border-top: none;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        cursor: pointer;
        font-size: 14px;
        line-height: 18px;
        padding: 10px;
        color: #333;
        @extend %transition;

        &.react-select__option--is-selected{
          background: $color1;
          color: #FFF;
        }

        &.react-select__option--is-focused{
          background: $color1;
          color: #FFF;
        }
      }
    }
  }
}

.rdrDateRangePickerWrapper{
  position: absolute;
  right: $spacing3;
  margin: -1px 0 0 0;
  border: 1px solid $color1;
  border-radius: $radius;
  overflow: hidden;
  font-family: 'Aspekta', Helvetica, Arial, sans-serif;
  text-transform: none;
  color: #000;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .rdrDefinedRangesWrapper{
    border-right: 1px solid $color1;

    .rdrStaticRanges{
      .rdrStaticRange{
        border-bottom: 1px solid #FFF;

        > span{
          @extend %transition;
        }

        &:hover{
          > span{
            background: #FFF;
          }
        }

        &.rdrStaticRangeSelected{
          > span{
            color: $color1;
          }
        }
      }
    }

    .rdrInputRanges{
      .rdrInputRange{
        input{
          @extend %transition;
        }
      }
    }
  }
}

.rdrCalendarWrapper.rdrDateRangeWrapper{
  &.month-only{
    position: absolute;
    right: 7.5px;
    left: 7.5px;
    z-index: 8;
    margin: -1px 0 0 0;
    border: 1px solid $color2;
    border-radius: $radius;
    overflow: hidden;
    font-family: 'Aspekta', Helvetica, Arial, sans-serif;
    text-transform: none;
    color: #000;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
  
  .rdrDateDisplayWrapper{
    display: none;
    // background: #FFF;

    // .rdrDateDisplay{
    //   .rdrDateInput.rdrDateDisplayItem{
    //     box-shadow: none;
    //     border-radius: 0;
    //     border: none;
    //     background: none;

    //     input{
    //       box-sizing: border-box;
    //       display: block;
    //       color: $colorText;
    //       font-size: 12px;
    //       width: 100%;
    //       padding: 0;
    //       margin: 0;
    //       border: none;
    //       box-sizing: border-box;
    //       appearance: none;
    //       -moz-appearance: none;
    //       -webkit-appearance: none;
    //       background: #FFF;
    //       border-radius: $radius - 5;
    //       font-family: 'Aspekta', Helvetica, Arial, sans-serif;
    //       @extend %transition;
    //     }
    //   }
    // }
  }

  .rdrMonthAndYearPickers{
    select{
      border-radius: $radius / 1.5;
      font-family: 'Aspekta', Helvetica, Arial, sans-serif;
    }
  }

  .rdrMonths{
    .rdrMonth{
      width: 100%;
      box-sizing: border-box;
      padding: 0 ($spacing - 10) ($spacing - 10) ($spacing - 10);

      .rdrMonthName{
        display: none;
      }

      .rdrDays{
        .rdrDay{
          
          > span{
            font-weight: normal;

            &.rdrDayStartPreview,
            &.rdrDayInPreview,
            &.rdrDayEndPreview{
              border-color: $color1;
            }

            &.rdrStartEdge,
            &.rdrInRange,
            &.rdrEndEdge{
              color: $color1 !important;
            }
          }
        }
      }
    }
  }
}

.Toastify__toast-container{
  top: 20px;
  right: 20px;
  padding: 0;

  .Toastify__toast{
    padding: $spacing4 $spacing3 $spacing3 $spacing3;
    margin-bottom: $spacing3;
    box-shadow: 0 5px 20px -10px rgba(0, 0, 0, .25);
    border-radius: $radius2;
    z-index: 9;

    &:after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 5px;
      width: 100%;
      background: rgba($color1, .25);
    }

    .Toastify__toast-body{
      font-size: 14px;
      line-height: 18px;
      font-family: 'Aspekta', Helvetica, Arial, sans-serif;
      font-weight: bold;

      span{
        display: block;
        margin: 2.5px 0 0 0;
        text-transform: none;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .Toastify__close-button{
      width: auto;
    }

    .Toastify__progress-bar{
      background: $color1;
    }
  }
}

.warranty-info{
  border: 1px solid $color1;
  padding: $spacing;
  border-radius: $radius;
  font-size: 12px;
  line-height: 16px;

  > ul{
    > li{
      > ul{
        > li{
          > .number{
            border: 2px solid $color1;
            color: $color1;
            padding: $spacing3 0 0 0;
            border-radius: $radius / 2;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            overflow: hidden;

            > div{
              background: $color1;
              color: #FFF;
              font-size: 12px;
              line-height: 16px;
              padding: $spacing3 $spacing4;
              margin: $spacing3 0 0 0;
            }

            &.disab{
              color: #8d8d8d;
              border: 2px solid #8d8d8d;

              > div{
                background: #8d8d8d;
              }
            }
          }

          > .prod{
            > img{
              float: left;
              border: 1px solid $color1;
              border-radius: $radius / 2;
              width: 34px;
              height: 34px;
              padding: $spacing4 / 2;
              margin: 0 $spacing4 0 0;
              box-sizing: border-box;
            }

            > strong{
              display: block;
            }
          }

          > .info{
            background: rgba($color1, .25);
            color: $color1;
            padding: $spacing3;
            border-radius: $radius / 2;
            font-weight: bold;
            text-align: center;

            &.error{
              background: rgba($colorError, .25);
              color: $colorError;
            }

            &.disab{
              background: rgba(#8d8d8d, .25);
              color: #8d8d8d;
            }
          }

          > strong{
            display: block;
            font-size: 14px;
            line-height: 18px;
          }

          > ul{
            padding: 5px 0 0 0;

            > li{
              > strong{
                display: block;
                font-size: 11px;
                line-height: 15px;
                color: #8d8d8d;
              }
            }

            &.high{
              margin: (-$spacing3) 0;

              > li{
                padding-left: $spacing3;
                border-left: 2px solid $color1;
                margin: $spacing3 0;

                > strong{
                  color: $colorText;
                  font-size: 12px;
                  line-height: 16px;
                }

                > em{
                  display: block;
                  font-style: normal;
                  // color: $colorLabel;
                  font-weight: bold;
                  font-size: 11px;
                  line-height: 15px;
                  color: #8d8d8d;
                }
              }
            }
          }

          > .prog{
            border: 1px solid $color1;
            border-radius: $radius;
            overflow: hidden;
            font-size: 9px;
            line-height: 18px;
            height: 18px;

            > div{
              background: $color1;
              color: #FFF;
              // padding: 0 $spacing4;
              box-sizing: border-box;
              font-weight: bold;
              text-align: right;
            }

            strong{
              margin: 0 $spacing4;
            }

            &.disab{
              border: 1px solid #8d8d8d;
            }
          }

          > .claim{
            margin: $spacing3 0 0 0;
            // padding: $spacing2;
            border: 1px solid $color1;
            border-radius: $radius / 2;

            > div{
              padding: $spacing;
              text-align: center;
            }

            > ul{
              margin: 0 !important;

              > li{
                border-bottom: 1px solid $color1;

                &:last-child{
                  border-bottom: none;
                }

                > ul{
                  padding: $spacing3;

                  > li{
                    > strong{
                      display: block;
                      font-size: 11px;
                      line-height: 15px;
                      color: #8d8d8d;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}